import React from 'react';
import './Error404.css'; // Don't forget to add this CSS

const Error404 = () => {
  return (
    <div className="error-container">
      <h1 className="error-code">404</h1>
      <p className="error-message">Oops! Looks like you're lost. 🙄</p>
      <p className="error-tip">
        Don't worry, even the best explorers get lost. <br />
        <a href="/" className="error-link">Go back to safety 🏠</a>
      </p>
      <div className="bouncing-box">👀</div>
    </div>
  );
};

export default Error404;
