import React from 'react';
import './Main.css';
import './Home.css';
import About from '../About/About';
import Services from '../Services/Services';
import Portfolio from '../Portfolio/Portfolio';
import Contact from '../Contact/Contact';
import StyleSwitcher from '../StyleSwitcher/StyleSwitcher';
import TypingEffect from './TpyingEffect'; // Adjust the path as necessary
import useScrollSpy from '../../hooks/useScrollSpy'; // Adjust the path as necessary

const Home = () => {
  const sectionIds = ['#home', '#about', '#services', '#portfolio', '#contact'];
  const activeSection = useScrollSpy(sectionIds);

  return (
    <div className="main-container">
      {/* <!-- Aide Start --> */}
      <aside className="aside">
        <div className="logo">
          <a href="https://justinbrierley.ca/"><span>J</span>ables</a>
        </div>
        <div className="nav-toggler">
          <span></span>
        </div>
        <ul className="nav">
          {sectionIds.map((id) => (
            <li key={id}>
              <a href={id} className={activeSection === id ? 'active' : ''}>
                <i className={`fas fa-${id.substring(1)}`}></i> {id.substring(1).charAt(0).toUpperCase() + id.substring(2)}
              </a>
            </li>
          ))}
        </ul>
      </aside>
      <div className="main-content">
        {/* <!-- Home Section Start --> */}
        <section className="home section" id="home">
          <div className="container">
            <div className="row">
              <div className="home-info padd-15">
                <h3 className="hello">Hello, my name is <span className="name">Justin Brierley</span></h3>
                <h3 className="my-profession">I am a <TypingEffect /></h3>
                <p>Hi there! My name is Justin Brierley, and I am a Web Developer specializing in React.js,
                  Angular, Laravel, JavaScript, and Python. I recently graduated from St. Clair College, 
                  where I earned a diploma in Web Development and Internet Applications.</p>
                <a href="#contact" className="btn hire-me">Hire Me</a>
              </div>
              <div className="home-img padd-15">
                <img src="media/justin.jpg" alt="Justin Brierley" />
              </div>
            </div>
          </div>
        </section>
        <About />
        <Services />
        <Portfolio />
        <Contact />
        <StyleSwitcher />
      </div>
    </div>
  );
};

export default Home;
