import React from 'react';
import './Contact.css';
const Contact = () => {
  return (
    <section className="contact section" id="contact">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Contact Me</h2>
          </div>
        </div>
        <h3 className="contact-title padd-15">Want to work with me?</h3>
        <h4 className="contact-sub-title padd-15">I am looking forward to hearing from you!</h4>
        <div className="row">
          {/* Contact Information Item Start */}
          <div className="contact-info-item padd-15">
            <div className="icon">
              <i className="fas fa-phone"></i>
            </div>
            <h4>Call Me At</h4>
            <a href="tel:+15199961094">+1 (519) 996-1094</a>
          </div>
          {/* Contact Information Item End */}

          {/* Contact Information Item Start */}
          <div className="contact-info-item padd-15">
            <div className="icon">
              <i className="fas fa-envelope"></i>
            </div>
            <h4>Email Me At</h4>
            <a href="mailto:justinbri35@gmail.com">justinbri35@gmail.com</a>
          </div>
          {/* Contact Information Item End */}

          {/* Contact Information Item Start */}
          <div className="contact-info-item padd-15">
            <div className="icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h4>City</h4>
            <p>Windsor, ON</p>
          </div>
          {/* Contact Information Item End */}

          {/* Contact Information Item Start */}
          <div className="contact-info-item padd-15">
            <div className="icon">
              <i className="fas fa-globe-americas"></i>
            </div>
            <h4>Website</h4>
            <a href="http://www.justinbrierley.ca" target="_blank" rel="noopener noreferrer">justinbrierley.ca</a>
          </div>
          {/* Contact Information Item End */}
        </div>

        <h3 className="contact-title padd-15">Send Me A Message</h3>
        <h4 className="contact-sub-title padd-15">I will try to reply to each message within 2 days!</h4>

        {/* Contact Form */}
        <form method="POST" action="https://formsubmit.co/dafc790e486388173555dc41e2be41b5" className="contact-form">
          <div className="row">
            <div className="form-item col-6 padd-15">
              <div className="form-group">
                <input type="text" placeholder="Name" name="name" className="form-control" id="name" />
              </div>
            </div>
            <div className="form-item col-6 padd-15">
              <div className="form-group">
                <input type="email" placeholder="Email" name="email" className="form-control" id="email" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-item col-12 padd-15">
              <div className="form-group">
                <input type="text" placeholder="Subject" name="subject" className="form-control" id="subject" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-item col-12 padd-15">
              <div className="form-group">
                <textarea name="message" className="form-control" id="message" placeholder="Message" cols="30" rows="10"></textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-item col-12 padd-15">
              <div className="form-group">
                <button type="submit" className="btn message">Send Message</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
