import React from 'react';
import './Services.css';


const Services = () => {
  return (
    <section className="service section" id="services">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Services</h2>
          </div>
        </div>
        <div className="row">
          {/* Service Item Start */}
          <div className="service-item padd-15">
            <div className="service-item-inner">
              <div className="icon">
                <i className="fas fa-laptop-code"></i>
              </div>
              <h4>Web Development</h4>
              <p>
                Create a modern, responsive, and user-friendly website tailored to meet your needs, using the latest technologies to boost your online presence and business success.
              </p>
            </div>
          </div>
          <div className="service-item padd-15">
            <div className="service-item-inner">
              <div className="icon">
                <i className="fas fa-globe"></i>
              </div>
              <h4>Web Deployment</h4>
              <p>Have a website made already and need it deployed? Look no further!</p>
            </div>
          </div>
          <div className="service-item padd-15">
            <div className="service-item-inner">
              <div className="icon">
                <i className="fas fa-database"></i>
              </div>
              <h4>Database Management</h4>
              <p>Need to clean up your database? Or better yet, do you need a database? Look no further!</p>
            </div>
          </div>
          <div className="service-item padd-15">
            <div className="service-item-inner">
              <div className="icon">
                <i className="fas fa-laptop-code"></i>
              </div>
              <h4>Website Updates</h4>
              <p>
                Don't let your website fall behind the times! Let me help you update your website to attract and retain more visitors, boost engagement, and increase sales. Contact me <a href="#contact">Here</a>.
              </p>
            </div>
          </div>
          {/* Service Item End */}
        </div>
      </div>
    </section>
  );
};

export default Services;
