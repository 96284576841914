import React from 'react';
import './About.css';
import { calculateAge } from '../../util/calculateAge';

const About = () => {
  const birthdate = '2000-08-03'; // Your birthdate in YYYY-MM-DD format
  const age = calculateAge(birthdate);

  // Handler for the download CV button
  const handleDownloadCV = (event) => {
    event.preventDefault();
    // Ensure the path is correct relative to the public directory
    const cvUrl = '/media/Justin_Brierley_Resume.pdf'; // Ensure this path is correct
    window.open(cvUrl, '_blank');
  };

  // Handler for the hire me button
  const handleHireMe = (event) => {
    event.preventDefault();
    const contactSection = document.querySelector('#contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="about section" id="about">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>About Me</h2>
          </div>
        </div>
        <div className="row">
          <div className="about-content padd-15">
            <div className="row">
              <div className="about-text padd-15">
                <h3>I'm Justin Brierley the <span>Web Developer</span></h3>
                <p>
                  I have a passion for creating dynamic and innovative websites that provide an 
                  exceptional user experience. Over the course of my studies, I gained a deep 
                  understanding of web development best practices, including responsive design, 
                  server-side scripting, and database management.
                </p>
                <p>
                  When I'm not coding, you can usually find me reading up on the latest tech news or 
                  exploring new programming languages. I am excited to bring my skills and passion for 
                  web development to new challenges and projects, and I look forward to the opportunity
                  to work with you!
                </p>
              </div>
            </div>
            <div className="row">
              <div className="personal-info padd-15">
                <div className="row">
                  <div className="info-item padd-15">
                  <p>Age: <span>{age}</span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>Website: <span><a href="http://www.justinbrierley.ca" target="_blank" rel="noopener noreferrer">www.justinbrierley.ca</a></span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>Email: <span><a href="mailto:justinbri35@gmail.com">justinbri35@gmail.com</a></span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>Degree: <span>Web Development</span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>Phone Number: <span><a href="tel:+15199961094">(519) 996-1094</a></span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>City: <span>Windsor</span></p>
                  </div>
                </div>
                <div className="row">
                  <div className="buttons padd-15">
                    <button className="btn" onClick={handleDownloadCV}>Download CV</button>
                    <button className="btn hire-me" onClick={handleHireMe}>Hire Me</button>
                  </div>
                </div>
              </div>
              <div className="skills padd-15">
                <div className="row">
                  <div className="skill-item padd-15">
                    <h5>Laravel</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: '83%' }}></div>
                      <div className="skill-percent">83%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>React.js</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: '76%' }}></div>
                      <div className="skill-percent">76%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>JavaScript</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: '92%' }}></div>
                      <div className="skill-percent">92%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>Angular</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: '87%' }}></div>
                      <div className="skill-percent">87%</div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>


            <dt>

            </dt>
            <dd>

            </dd>
            


            <div className="row">
              <div className="education padd-15">
                <h3 className="title">Education</h3>
                <div className="row">
                  <div className="timeline-box padd-15">
                    <div className="timeline shadow-dark">
                      {/* timeline item */}
                      <div className="timeline-item">
                        <div className="circle-dot"></div>
                        <h3 className="timeline-date">
                          <i className="fa fa-calendar"></i> 2021 - 2023
                        </h3>
                        <h4 className="timeline-title">Diploma in Web Development</h4>
                        <p className="timeline-text">
                          Throughout the program, I learned how to design and develop dynamic websites and web applications 
                          using various programming languages such as HTML, CSS, JavaScript, and PHP. I gained hands-on 
                          experience in web server administration, database development, and mobile application development.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="experience padd-15">
                <h3 className="title">Experience</h3>
                <div className="row">
                  <div className="timeline-box padd-15">
                    <div className="timeline shadow-dark">
                      {/* timeline item */}
                      <div className="timeline-item">
                        <div className="circle-dot"></div>
                        <h3 className="timeline-date">
                          <i className="fa fa-calendar"></i> 2023
                        </h3>
                        <h4 className="timeline-title">Dementia Network Calgary</h4>
                        <p className="timeline-text">
                          Developing Dementia Network Calgary's website in a group was collaborative and rewarding. We divided 
                          tasks by strength, held meetings to share progress, and aimed to create an informative, user-friendly 
                          resource for those affected by dementia. Open communication and mutual respect helped us overcome 
                          challenges and create a high-quality website that exceeded expectations.
                        </p>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>         
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
