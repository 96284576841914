import React, { useEffect, useState } from 'react';
import './style-switcher.css';

const StyleSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [theme, setTheme] = useState('color-1'); // Default theme

  const toggleStyleSwitcher = () => {
    setIsOpen(!isOpen);
  };

  const setActiveStyle = (color) => {
    setTheme(color);
  };

  const toggleDarkMode = () => {
    document.body.classList.toggle("dark");
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isOpen]);

  useEffect(() => {
    const isDark = document.body.classList.contains("dark");
    setIsDarkMode(isDark);
  }, []);

  return (
    <div className={`style-switcher ${isOpen ? 'open' : ''}`}>
      <div className="style-switcher-toggler s-icon" onClick={toggleStyleSwitcher}>
        <i className="fas fa-cog fa-spin"></i>
      </div>
      <div className="day-night s-icon" onClick={toggleDarkMode}>
        <i className={`fas ${isDarkMode ? 'fa-sun' : 'fa-moon'}`}></i>
      </div>
      <h4>Theme Colours</h4>
      <div className="colors">
        <span className="color-1" onClick={() => setActiveStyle('color-1')}></span>
        <span className="color-2" onClick={() => setActiveStyle('color-2')}></span>
        <span className="color-3" onClick={() => setActiveStyle('color-3')}></span>
        <span className="color-4" onClick={() => setActiveStyle('color-4')}></span>
        <span className="color-5" onClick={() => setActiveStyle('color-5')}></span>
      </div>
      <link rel="stylesheet" href={`skins/${theme}.css`} />
    </div>
  );
};

export default StyleSwitcher;
