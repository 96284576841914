import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
  return (
    <section className="portfolio section" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Portfolio</h2>
          </div>
        </div>
        <div className="row">
          <div className="portfolio-heading padd-15">
            <h2>My Past Projects: </h2>
          </div>
        </div>
        <div className="row">
          <div className="portfolio-heading text-center padd-15">
            <h2>Full Stack Projects</h2>
          </div>
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://groovtamtattoos.ca/" target="_blank">
                  <img src="../media/tattoo_website.png" alt="groovtamtattoos website" />
                </a> 
              </div>
            </div>
          </div>
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://dementianetworkcalgary.ca" target="_blank">
                  <img src="../media/dementianetworkcalgary.png" alt="Dementia Network Calgary" />
                </a> 
              </div>
            </div>
          </div>
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://react-firebase-social-media-app.vercel.app/protected/dashboard" target="_blank">
                  <img src="../media/socialmediaapp.jpg" alt="React Firebase Social Media" />
                </a> 
              </div>
            </div>
          </div>
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://react-firebase-baseball-blog.vercel.app/" target="_blank">
                  <img src="../media/react-baseball-blog.png" alt="React Firebase Blog App" />
                </a> 
              </div>
            </div>
          </div>
          
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://react-firebase-todo-app.vercel.app/" target="_blank">
                  <img src="../media/react-todo.png" alt="React Firebase Todo App" />
                </a> 
              </div>
            </div>
          </div>
          
        </div>
        <div className="row">
          <div className="portfolio-heading text-center padd-15">
            <h2>Static Projects</h2>
          </div>
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://photography-website-ivory-six.vercel.app/" target="_blank">
                  <img src="../media/photography.png" alt="React photography portfolio website" />
                </a> 
              </div>
            </div>
          </div>
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://angular-todo-app-bulma-css.vercel.app/" target="_blank">
                  <img src="../media/Angular-Todo-App.png" alt="Angular Todo App" />
                </a> 
              </div>
            </div>
          </div>
          <div className="portfolio-item padd-15">
            <div className="portfolio-item-inner shadow-dark">
              <div className="portfolio-img">
                <a href="https://pc-outfitters-upgrade.vercel.app/" target="_blank">
                  <img src="../media/pc_outfitters.png" alt="PC Outfitters Redesign" />
                </a> 
              </div>
            </div>
          </div>   
        </div>
        </div> 
        
    </section>
  )
}

export default Portfolio
