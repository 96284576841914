// src/components/TypingEffect.js
import React, { useEffect } from 'react';
import Typed from 'typed.js';

const TypingEffect = () => {
  useEffect(() => {
    // Initialize Typed.js
    const options = {
      strings: ["Web Developer", "Programmer", "IT Support Specialist", "Cat Lover"],
      typeSpeed: 100,
      backSpeed: 60,
      loop: true
    };

    const typed = new Typed(".typing", options);

    // Clean up the Typed instance when the component unmounts
    return () => {
      typed.destroy();
    };
  }, []);

  return <span className="typing"></span>;
};

export default TypingEffect;
