import React from "react";
import { createBrowserRouter, useParams } from "react-router-dom";

import Home from "../Components/Layout/Home";
import Error404 from "../Components/Error404/Error404";

export const ROOT = "/";


export const router = createBrowserRouter([
    {
        path: ROOT,
        element: <Home />
    },
    {
        path: "*", 
        element: <Error404 />
    }
])